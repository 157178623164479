import React from "react";
import styled from "styled-components";

const HEIGHT = 59;

const BigTextInput = ({ searchValue, handleSearch, handleFocus }) => {
  return (
    <Wrapper
      value={searchValue}
      onFocus={() => handleFocus(true)}
      onBlur={() => handleFocus(false)}
      onChange={ev => handleSearch(ev.target.value)}
    />
  );
};

const Wrapper = styled.input`
  display: block;
  flex: 1;
  border: none;
  background: transparent;
  font-size: 34px;
  height: ${HEIGHT}px;
  font-family: var(--font-family-mono);
  text-align: center;
  color: inherit;

  outline: none;

  /* Input is too big on mobile sizes, it winds up not being centered. Allow it to shrink down. */
  min-width: 0px;
`;

export default BigTextInput;
