import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

const Boop = ({
  x = 0,
  y = 0,
  rotation = 0,
  scale = 1,
  children,
  twitchTiming = 150,
  ...delegated
}) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const [isBooped, setIsBooped] = React.useState(false);

  const style = useSpring({
    transform: isBooped
      ? `translate(${x}px, ${y}px) rotate(${rotation}deg) scale(${scale})`
      : `translate(0px, 0px) rotate(0deg) scale(1)`,
    config: {
      tension: 300,
      friction: 10
    }
  });

  React.useEffect(() => {
    if (!isHovering) {
      setIsBooped(false);
      return;
    }

    setIsBooped(true);

    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, twitchTiming);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isHovering, twitchTiming]);

  if (typeof children === "function") {
    return (
      <Wrapper
        as="span"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        {...delegated}
      >
        {children(style)}
      </Wrapper>
    );
  }

  return (
    <Wrapper
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={style}
      {...delegated}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(animated.span)`
  display: inline-block;
  backface-visibility: hidden;
`;

export default Boop;
