import styled from "styled-components";

const FakeTextLink = styled.span`
  display: inline-block;
  position: relative;
  font-weight: var(--font-weight-medium);
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    left: -2px;
    right: -2px;
    bottom: 0px;
    height: 2px;
    background-color: ${props =>
      props.color ? props.color : "var(--color-primary)"};
    border-radius: 2px;
    transition: transform 200ms 150ms, opacity 350ms 150ms;
  }
`;

export default FakeTextLink;
