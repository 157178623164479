import React, { useState, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import styled from "styled-components";
import { useQueryParam, StringParam } from "use-query-params";

import { motion } from "framer-motion";

import Layout from "@components/layout";
import Heading from "@components/operator-lookup/Heading";
import Paragraph from "@components/operator-lookup/Paragraph";
import Search from "@components/operator-lookup/Search";
import Spacer from "@components/operator-lookup/Spacer";
import Match from "@components/operator-lookup/Match";
import Warning from "@components/operator-lookup/Warning";
import PillList from "@components/operator-lookup/PillList";
import Query from "@components/operator-lookup/Query";

import useKeyboardEvent from "@hooks/use-keyboard-event";
import useHasMounted from "@hooks/use-has-mounted";
import classify from "../classify";
import "./operator-lookup.scss";

import imgCard from "@data/operator-lookup/card.png";

const nameOfPath = path =>
  path
    .match(/\/([a-zA-Z0-9_-]*)\.md$/)[1]
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

const OperatorLookupPage = ({ data }) => {
  const index = data.index;

  const { operatorByName, operatorByGroup } = useMemo(() => {
    const pills = data.operatorData.nodes
      .map(({ fileAbsolutePath, body, frontmatter }) => {
        const name = frontmatter.name || nameOfPath(fileAbsolutePath);
        return {
          ...frontmatter,
          body: body,
          external: Boolean(frontmatter.external),
          name: name
        };
      })
      .sort((a, b) => (a.syntax < b.syntax ? -1 : a.syntax > b.syntax ? 1 : 0));

    const operatorByName = new Map(pills.map(i => [i.syntax, i]));

    // Get groups
    const operatorByGroup = new Map();
    pills.forEach(pill => {
      const key = pill.group ? pill.group : "other";
      const collection = operatorByGroup.get(key);

      if (!collection) {
        operatorByGroup.set(key, [pill]);
      } else {
        collection.push(pill);
      }
    });

    return { operatorByName: operatorByName, operatorByGroup: operatorByGroup };
  }, [data]);

  const [query, setQuery] = useQueryParam("q", StringParam);
  const [matchedOperator, setMatchedOperator] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [searchValue, setSearchValue] = useState(query || "");
  const [showNoMatch, setShowNoMatch] = useState(false);
  const hasMounted = useHasMounted();

  const unmatchDelay = 500; /* Delay before unmatching an operator */
  const displayNoMatch = 2000; /* Time between unmatching and showing an error */

  useEffect(() => {
    let timeoutId;
    let match = operatorByName.get(searchValue);

    if (match) {
      let others = [];

      if (match.group) {
        others = operatorByGroup
          .get(match.group)
          .filter(operator => operator !== match);
      }

      setShowNoMatch(false);
      setQuery(searchValue);
      setMatchedOperator({ matched: match, others: others });
    } else {
      const classifiable = classify(searchValue);

      if (classifiable) {
        let body;

        // prettier-ignore
        switch (classifiable.name) {
          case "prefix":            body = data.prefix.body;           break;
          case "left-associative":  body = data.leftAssociative.body;  break;
          case "right-associative": body = data.rightAssociative.body; break;
          case "Let Binding":       body = data.letBinding.body;       break;
          case "And Binding":       body = data.andBinding.body;       break;
          default: throw new Error("Unknown operator classification: " + classifiable.name);
        }

        if (body) body = body.replaceAll("#SYNTAX#", searchValue);

        const matched = {
          ...classifiable,
          syntax: searchValue,
          body: body,
          external: true,
          highlightName: false
        };

        setShowNoMatch(false);
        setQuery(searchValue);
        setMatchedOperator({ matched: matched, others: [] });
      } else {
        if (showNoMatch) setShowNoMatch(false);

        timeoutId = window.setTimeout(() => {
          setMatchedOperator(null);
          setQuery(undefined);

          timeoutId = window.setTimeout(() => {
            if (searchValue) setShowNoMatch(true);
          }, displayNoMatch);
        }, unmatchDelay);
      }
    }

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [searchValue]);

  const clearSearch = () => {
    setMatchedOperator(null); // Unset matched operator explicitly to avoid delay
    setSearchValue("");
  };

  const handleClear = React.useCallback(clearSearch, [
    setSearchValue,
    setMatchedOperator
  ]);

  const handleSearch = React.useCallback(setSearchValue, [setSearchValue]);

  useKeyboardEvent("Escape", clearSearch);

  let globalMdxComponents = {
    Warning: Warning,
    AsideRule: () => (
      <>
        <Spacer size={40} />
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <Spacer size={40} />
      </>
    ),
    Query: Query(setSearchValue)
  };

  // Text for structural comparisons is implemented with a component that
  // requires access to the previous components. So we must add it to
  // `globalMdxComponents` separately.

  const StructuralComparisonText = ({
    onLessThan,
    onEqualTo,
    onGreaterThan,
    name,
    syntax
  }) => (
    <MDXProvider components={globalMdxComponents}>
      <MDXRenderer>
        {data.structuralComparison.body
          .replace("#NAME#", name)
          .replaceAll("#SYNTAX#", syntax)
          .replaceAll("#LESS_THAN#", onLessThan)
          .replace("#GREATER_THAN#", onGreaterThan)}
      </MDXRenderer>
    </MDXProvider>
  );

  const LetOperatorText = ({ syntax }) => (
    <MDXProvider components={globalMdxComponents}>
      <MDXRenderer>
        {data.letBinding.body.replaceAll("#SYNTAX#", syntax)}
      </MDXRenderer>
    </MDXProvider>
  );

  const AndOperatorText = ({ syntax }) => (
    <MDXProvider components={globalMdxComponents}>
      <MDXRenderer>
        {data.andBinding.body.replaceAll("#SYNTAX#", syntax)}
      </MDXRenderer>
    </MDXProvider>
  );

  globalMdxComponents.StructuralComparisonText = StructuralComparisonText;
  globalMdxComponents.LetOperatorText = LetOperatorText;
  globalMdxComponents.AndOperatorText = AndOperatorText;

  return (
    <Layout
      title="Operator Lookup"
      seo={{
        image: imgCard,
        description: "An operator lookup utility for OCaml."
      }}
    >
      <div className="operator-lookup max-width-wrapper center-aligned spacing-reset">
        {(!matchedOperator || !hasMounted) && (
          <Header
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Heading type="large-title">Operator Lookup</Heading>
            <Spacer size={10} />
            <Paragraph>
              Enter an <strong style={{ color: "#EF7A08" }}>OCaml</strong>{" "}
              operator to learn more about it:
            </Paragraph>
            <Spacer size={32} />
          </Header>
        )}
        <Search
          searchValue={searchValue}
          isFocused={isFocused}
          handleSearch={handleSearch}
          handleClear={handleClear}
          handleFocus={setIsFocused}
          color={"var(--lookup-color-primary)"}
        />
        {showNoMatch && (
          <NoMatch
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { opacity: 1, y: 0 }
            }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 30,
              restDelta: 0.01,
              restSpeed: 0.01
            }}
          >
            <strong>Sorry, that operator isn't recognised!</strong>
          </NoMatch>
        )}
        {matchedOperator && (
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 200 }}
          >
            <Match
              globalMdxComponents={globalMdxComponents}
              setSearchValue={setSearchValue}
              {...matchedOperator}
            />
          </motion.div>
        )}
        {!matchedOperator && (
          <MatchSuggestionsWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PillList
              label={"Or, pick one:"}
              pills={Array.from(operatorByGroup.entries()).sort()}
              setPill={setSearchValue}
            />
            <Spacer size={36} />
            <Explanation className="prose-container">
              <MDXProvider components={globalMdxComponents}>
                <MDXRenderer>{index.body}</MDXRenderer>
              </MDXProvider>
            </Explanation>
          </MatchSuggestionsWrapper>
        )}
      </div>
    </Layout>
  );
};

const Header = styled(motion.header)`
  position: relative;
  z-index: 1;
`;

const Explanation = styled(motion.div)`
  text-align: left;
  font-size: 16px;

  h2 {
    font-size: 23px;
    margin-top: 36px;
  }
`;

const NoMatch = styled(motion.p)`
  margin-top: 48px !important;
  color: var(--color-gray-900);
  will-change: transform;
`;

const MatchSuggestionsWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-top: 72px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 48px;

  @media ${p => p.theme.breakpoints.mdAndLarger} {
    margin-left: -12px;
    margin-right: -12px;
  }
`;

export const pageQuery = graphql`
  query {
    prefix: mdx(
      fileAbsolutePath: {
        regex: "//data/operator-lookup/templates/prefix.md$/"
      }
    ) {
      body
    }
    leftAssociative: mdx(
      fileAbsolutePath: {
        regex: "//data/operator-lookup/templates/left-associative.md$/"
      }
    ) {
      body
    }
    rightAssociative: mdx(
      fileAbsolutePath: {
        regex: "//data/operator-lookup/templates/right-associative.md$/"
      }
    ) {
      body
    }
    letBinding: mdx(
      fileAbsolutePath: {
        regex: "//data/operator-lookup/templates/let-binding.md$/"
      }
    ) {
      body
    }
    andBinding: mdx(
      fileAbsolutePath: {
        regex: "//data/operator-lookup/templates/and-binding.md$/"
      }
    ) {
      body
    }
    structuralComparison: mdx(
      fileAbsolutePath: {
        regex: "//data/operator-lookup/templates/structural-comparison.md$/"
      }
    ) {
      body
    }
    operatorData: allMdx(
      sort: { order: ASC, fields: [frontmatter___syntax] }
      filter: {
        fileAbsolutePath: {
          regex: "//data/operator-lookup/(?!__index__)[^(/)]*.md$/"
        }
      }
    ) {
      nodes {
        fileAbsolutePath
        body
        frontmatter {
          name
          syntax
          type
          external
          flavour
          group
        }
      }
    }
    index: mdx(
      fileAbsolutePath: { regex: "//data/operator-lookup/__index__.md$/" }
    ) {
      body
    }
  }
`;

export default OperatorLookupPage;
