import React from "react";
import styled from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Pills from "./Pills";

import { BREAKPOINTS } from "./constants";

const PillList = ({ label, pills, setPill }) => {
  const smallView = useMediaQuery(BREAKPOINTS.smAndSmaller);

  // Show in groups only if there's enough space.
  if (smallView) {
    const allPills = pills
      .flatMap(([groupName, pills]) => pills)
      .sort((a, b) => a.syntax.localeCompare(b.syntax));
    return <Pills pills={allPills} setPill={setPill} />;
  } else {
    return (
      <Table>
        <tbody>
          {pills.map(([groupName, pills]) => (
            <Row key={groupName}>
              <td style={{ textAlign: "right" }}>{groupName}:</td>
              <td style={{ textAlign: "left" }}>
                <Pills pills={pills} setPill={setPill} />
              </td>
            </Row>
          ))}
        </tbody>
      </Table>
    );
  }
};

const Table = styled.table`
  margin: 0 auto;
`;

const Row = styled.tr`
  margin: 0;

  & ul {
    margin-top: 6px;
    margin-bottom: 2px;
    margin-left: 10px;
  }

  & a {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export default PillList;
