import React from "react";
import CategoryPill from "./CategoryPill";
import styled from "styled-components";
import { motion } from "framer-motion";

const Pills = ({ pills, setPill }) => (
  <PillsWrapper>
    {pills.map((pill, index) => (
      <motion.li
        key={index}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 }
        }}
      >
        <CategoryPill onClick={() => setPill(pill.syntax)}>
          {pill.syntax}
        </CategoryPill>
      </motion.li>
    ))}
  </PillsWrapper>
);

const PillsWrapper = styled(motion.ul)`
  flex: 3.5;
  padding-left: 0;

  li {
    display: inline-block;
  }

  button {
    font-family: var(--font-family-mono);
  }

  @media ${p => p.theme.breakpoints.smAndSmaller} {
    /*
      Increase spacing to make it less easy to accidentally tap
      the wrong operator
    */
    li {
      padding: 4px;
    }
  }
`;

export default Pills;
