/* Functions for classifying OCaml operators and their associativities. See the
   OCaml manual for details. */

const coreOperatorChar = /[$&*+/=>@^|-]/;
const operatorChar = new RegExp(coreOperatorChar.source + "|[~!?%<:.]");
const dotOperatorChar = new RegExp(coreOperatorChar.source + "|[!?%:]");

const infixSymbol = new RegExp(
  `^(${coreOperatorChar.source}|[%<])(${operatorChar.source})*$`
);

const bindingOperatorSuffix = `((${coreOperatorChar.source})|<)(${dotOperatorChar.source})*$`;
const letOperator = new RegExp("^let" + bindingOperatorSuffix);
const andOperator = new RegExp("^and" + bindingOperatorSuffix);

const prefixSymbol = new RegExp(`^[!?~](${operatorChar.source})+$`);

const determineAssociativity = token => {
  const leftAssociative = /^[#(*^(*))/%+\-=<>|&$]/;
  const rightAssociative = /^[\^@(**)]/;

  if (token.match(leftAssociative)) return "left-associative";
  if (token.match(rightAssociative)) return "right-associative";

  return new Error("Could not determine associativity");
};

const classify = token => {
  if (!token || token === "->") return null;

  if (token.match(infixSymbol)) {
    const name = determineAssociativity(token);
    return {
      name: name
    };
  } else if (token.match(prefixSymbol)) {
    return {
      name: "prefix"
    };
  } else if (token.match(letOperator)) {
    return {
      name: "Let Binding",
      flavour: "operator"
    };
  } else if (token.match(andOperator)) {
    return {
      name: "And Binding",
      flavour: "operator"
    };
  } else {
    return null;
  }
};

export default classify;
