import React from "react";
import styled from "styled-components";
import { Icon } from "react-icons-kit";
import { info as infoIcon } from "react-icons-kit/feather/info";
import { alertTriangle as warningIcon } from "react-icons-kit/feather/alertTriangle";
import { checkCircle as successIcon } from "react-icons-kit/feather/checkCircle";

const COLOR_SWAP_TRANSITION_DURATION = 2;

const Sidenote = ({ type = "info", title, children }) => {
  let Component;
  let icon;
  if (type === "info") {
    Component = InfoSidenote;
    icon = infoIcon;
  } else if (type === "success") {
    Component = SuccessSidenote;
    icon = successIcon;
  } else if (type === "warning") {
    Component = WarningSidenote;
    icon = warningIcon;
  } else {
    console.error(`Unrecognized Sidenote type: ${type}`);
    Component = InfoSidenote;
    icon = infoIcon;
  }

  return (
    <Component>
      <IconWrapper>
        <Icon icon={icon} size={32} />
      </IconWrapper>
      {title && <Title>{title}</Title>}
      {children}
    </Component>
  );
};

export const BaseWrapper = styled.aside`
  position: relative;
  padding-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 24px;
  /* font-style: italic; */
  font-size: 17px;
  margin-top: 48px;
  margin-bottom: 64px;
  border-left: 3px solid;
  border-radius: 6px 6px 6px 3px;

  @media (min-width: 1150px) {
    margin-left: -32px;
    margin-right: -32px;
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }
`;

export const InfoSidenote = styled(BaseWrapper)`
  background: var(--color-muted);
  border-color: var(--color-info);
  transition: background ${COLOR_SWAP_TRANSITION_DURATION}ms;
`;

export const SuccessSidenote = styled(BaseWrapper)`
  background: var(--color-success-background);
  border-color: var(--color-success);
  transition: background ${COLOR_SWAP_TRANSITION_DURATION}ms;
`;

export const WarningSidenote = styled(BaseWrapper)`
  background: var(--color-alert-background);
  border-color: var(--color-alert);
  transition: background ${COLOR_SWAP_TRANSITION_DURATION}ms;
`;

const Title = styled.strong`
  display: block;
  font-size: 17px;
  margin-bottom: 8px;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  padding: 8px;
  background: var(--color-background);
  transition: background ${COLOR_SWAP_TRANSITION_DURATION}ms;
  border-radius: 50%;

  ${InfoSidenote} & {
    color: var(--color-info);
  }
  ${SuccessSidenote} & {
    color: var(--color-success);
  }
  ${WarningSidenote} & {
    color: var(--color-alert);
    border-radius: 25% 25%;
  }
`;

export default Sidenote;
