import React from "react";
import styled from "styled-components";

import { Icon } from "react-icons-kit";
import { alertTriangle } from "react-icons-kit/feather/alertTriangle";

const Warning = ({ children }) => (
  <WarningWrapper>
    <Tab>
      <Icon icon={alertTriangle} size={22} /> Warning
    </Tab>
    <WarningBody>{children}</WarningBody>
  </WarningWrapper>
);

const WarningWrapper = styled.div`
  position: relative;
  margin-top: 70px;
`;

const WarningBody = styled.div`
  @media (min-width: 610px) {
    background: var(--color-alert-background);
    padding: 16px;
    border-radius: 8px;
  }

  border: 2px solid var(--color-alert);

  pre {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }
`;

const Tab = styled.div`
  position: absolute;
  z-index: 2;
  left: 14px;
  transform: translateY(-100%);

  // Positioning hack to overwrite dark border around the entire component
  top: 2px;
  border: 2px solid var(--color-alert);
  border-radius: 8px 8px 0 0;
  border-bottom-color: var(--color-alert-background);

  padding: 2px 12px 0px;
  background: var(--color-alert-background);

  color: var(--color-alert);
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family);
  pointer-events: none;
`;

export default Warning;
