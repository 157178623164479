import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Icon } from "react-icons-kit";
import { search } from "react-icons-kit/feather/search";
import { x } from "react-icons-kit/feather/x";

import Spinner from "./Spinner";
import Spacer from "./Spacer";
import Boop from "./Boop";
import UnstyledButton from "./UnstyledButton";
import BigTextInput from "./BigTextInput";

import useHasMounted from "@hooks/use-has-mounted";

const COLOR_SWAP_TRANSITION_DURATION = 1;
const HEIGHT = 59;
const ICON_SIZE = 24;

const Search = ({
  prefersReducedMotion,
  searchValue,
  isFocused,
  handleSearch,
  handleClear,
  handleFocus,
  color
}) => {
  const hasMounted = useHasMounted();

  return (
    <Wrapper layout={!prefersReducedMotion}>
      <InputLabel isFocused={isFocused} color={color}>
        <SearchIconWrapper>
          <Icon icon={search} size={ICON_SIZE} />
        </SearchIconWrapper>
        {hasMounted ? (
          <BigTextInput
            searchValue={searchValue}
            handleSearch={handleSearch}
            handleFocus={handleFocus}
          />
        ) : (
          <SpinnerWrapper>
            <Spinner size={24} />
          </SpinnerWrapper>
        )}
        {searchValue ? (
          <Boop>
            <CloseButton onClick={handleClear} color={color}>
              <Icon icon={x} size={ICON_SIZE} />
            </CloseButton>
          </Boop>
        ) : (
          <Spacer size={ICON_SIZE} />
        )}
      </InputLabel>
      <BackgroundBox style={{ opacity: isFocused ? 1 : 0 }} />
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  padding: 2px;
  transition: background ${COLOR_SWAP_TRANSITION_DURATION}ms;
  will-change: transform;
`;

const SpinnerWrapper = styled.div`
  flex: 1;
  display: grid;
  place-content: center;
  height: ${HEIGHT}px;
  opacity: 0.4;
`;

const BackgroundBox = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: opacity 600ms;
  /* prettier-ignore */
  box-shadow:
    0 0.9px 1.2px rgba(0, 0, 0, 0.064),
    0 2.1px 2.9px rgba(0, 0, 0, 0.07),
    0 3.9px 5.5px rgba(0, 0, 0, 0.072),
    0 6.9px 9.8px rgba(0, 0, 0, 0.076),
    0 13px 18.4px rgba(0, 0, 0, 0.092),
    0 31px 44px rgba(0, 0, 0, 0.17),
    0 -5px 15px rgba(0, 0, 0, 0.1)
  ;
`;

const InputLabel = styled.label`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 0 12px 4px;
  border-radius: 8px;
  overflow: hidden;
  color: var(--color-text);
  font-variant-ligatures: none;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background: ${p =>
      p.isFocused
        ? p.color
          ? p.color
          : "var(--color-primary)"
        : "var(--color-gray1000)"};
    border-radius: 100px;
  }

  &:focus:after {
  }
`;

const SearchIconWrapper = styled.span`
  opacity: 0.75;
`;

const CloseButton = styled(UnstyledButton)`
  color: inherit;
  opacity: 0.75;

  &:hover {
    color: ${props => (props.color ? props.color : "var(--color-secondary)")};
    opacity: 1;
  }

  i,
  svg {
    display: block !important;
  }
`;

export default Search;
