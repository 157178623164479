import React from "react";

import Prism from "prismjs";
import "prismjs/components/prism-ocaml";

import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import styled, { keyframes } from "styled-components";

import Highlight, { defaultProps } from "prism-react-renderer";

import FakeTextLink from "./FakeTextLink";
import Spacer from "./Spacer";
import Pills from "./Pills";

const stdlibUrl = syntax =>
  `https://docs.mirage.io/ocaml/Stdlib/index.html#val-(${syntax})`;

// Approximate the indefinite article for word
const indefiniteArticleFor = x => {
  const vowelSound = x.match(/^[aeiouAEIOU]/);
  return vowelSound ? "an" : "a";
};

const Match = ({ matched, others, globalMdxComponents, setSearchValue }) => {
  const { name, syntax, type, external, flavour, body, group } = matched;
  const code = `val ( ${syntax} ) : ${type}`;

  const highlightName =
    matched.highlightName || matched.highlightName === undefined;
  const article = external ? indefiniteArticleFor(name) : "the";

  // TODO: refactor
  const flav = flavour === null || flavour === undefined ? "operator" : flavour;
  const flavourText = flav === "syntax" ? "" : " " + flav;

  React.useEffect(() => {
    Prism.highlightAll();
  });

  return (
    <Wrapper>
      <Intro>
        This is {article}{" "}
        <strong className={highlightName !== false ? "secondary-color" : null}>
          {name}
        </strong>
        {flavourText}.
      </Intro>
      {type && (
        <>
          <Highlight
            {...defaultProps}
            code={code}
            language="ocaml"
            theme={undefined}
          >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre className={className} style={style}>
                {tokens.map((line, i) => (
                  <div {...getLineProps({ line, key: i })}>
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                ))}
              </pre>
            )}
          </Highlight>
        </>
      )}
      <DefinitionWrapper className="content prose-container">
        <MDXProvider components={globalMdxComponents}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </DefinitionWrapper>
      {group && (
        <div className="center-aligned">
          <Spacer size={20} />
          <Label>{`Other ${group} operators:`}</Label>
          <Pills pills={others} setPill={setSearchValue} />
        </div>
      )}
      {!external && flavour === "operator" && (
        <DocLink
          href={stdlibUrl(syntax)}
          target="_blank"
          rel="noopener noreferer"
        >
          See the <FakeTextLink>documentation</FakeTextLink> of this operator.
        </DocLink>
      )}
    </Wrapper>
  );
};

const Label = styled.span`
  display: block;
  flex: 1;
  font-size: 16px;
  transition: var(--theme-transition);
  color: var(--color-gray900);
  margin-bottom: 16px;
`;

const Intro = styled.p`
  font-size: 21px;
  margin-top: 0px;
  margin-bottom: 42px;
  text-align: center;
`;

const DocLink = styled.a`
  display: block;
  transition: var(--theme-transition);
  color: var(--color-gray1000);
  margin-top: 48px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 18px;
  text-decoration: none;

  @media (min-width: 610px) {
    text-align: center;
    background: var(--color-muted);
    padding: 16px;
    border-radius: 8px;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Wrapper = styled.div`
  position: relative;
  z-index: 3;
  text-align: left;
  margin-top: 64px;
  animation: ${fadeIn} 500ms 500ms both;
`;

const DefinitionWrapper = styled.div`
  margin: 32px 0;
  font-size: 18px;
  line-height: 28px;

  & :not(pre) > code {
    font-size: 16px !important;
  }

  p {
    margin-bottom: 24px;
  }
`;

export default Match;
