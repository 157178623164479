import React from "react";
import FakeTextLink from "@components/operator-lookup/FakeTextLink";

const Query = setter => ({ target }) => {
  return (
    <FakeTextLink
      color="var(--lookup-color-primary)"
      onClick={() => {
        setter(target);
      }}
    >
      {"( "}
      <code
        style={{
          background: "none",
          color: "inherit",
          fontFamily: "var(--font-family-mono)"
        }}
      >
        {target}
      </code>
      {" )"}
    </FakeTextLink>
  );
};

export default Query;
