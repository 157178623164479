import { useEffect } from "react";

const useKeyboardEvent = (key, action) => {
  useEffect(() => {
    const onKeydown = e => {
      if (e.key === key) {
        e.preventDefault();
        action();
      }
    };
    window.addEventListener("keydown", onKeydown);
    return () => window.removeEventListener("keydown", onKeydown);
  }, []);
};

export default useKeyboardEvent;
