import React from "react";
import styled, { keyframes } from "styled-components";
import { Icon } from "react-icons-kit";
import { loader } from "react-icons-kit/feather/loader";

const Spinner = ({ size = 24, color }) => {
  return (
    <Wrapper
      style={{ width: size, height: size, "--size": size + "px", color }}
    >
      <Icon icon={loader} size={size} />
    </Wrapper>
  );
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.span`
  display: inline-block;
  color: var(--color-text);
  opacity: 0.75;
  animation: ${spin} 1400ms linear infinite;
  transform-origin: center center;

  i {
    height: var(--size);
  }

  svg {
    display: block !important;
  }
`;

export default Spinner;
