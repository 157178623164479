import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import GatsbyLink from "gatsby-link";

const CategoryPill = ({ href, onClick, children }) => {
  const [isHovering, setIsHovering] = React.useState(false);

  const backgroundSpring = useSpring({
    opacity: isHovering ? 0.5 : 0.33,
    transform: isHovering ? `scale(1.06)` : `scale(0.99)`,
    config: {
      tension: 450,
      friction: 25
    }
  });

  return (
    <LinkWrapper
      as={"a"}
      href={href}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onClick={onClick}
    >
      <Background style={backgroundSpring} />
      {children}
    </LinkWrapper>
  );
};

const LinkWrapper = styled(GatsbyLink)`
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;

  padding: 3px 12px;
  margin-right: 8px;
  margin-bottom: 8px;

  font-size: 14px;
  font-family: var(--font-family-mono);
  font-weight: var(--font-weight-medium);
`;

const Background = styled(animated.div)`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 5px;

  background-color: var(--color-alert);
  transform-origin: center center;
`;

export default CategoryPill;
